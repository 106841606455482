<template>
  <p v-if="offerBookCall">
    <v-btn
      v-bind="outlineButton"
      @click="bookCall = true"
      >Book a call</v-btn
    >
    <v-navigation-drawer
      width="500"
      temporary
      app
      right
      v-model="bookCall"
      id="book-call"
    >
      <div class="header">
        <v-toolbar
          flat
          class="transparent"
          dense
        >
          <btn-outline
            icon="mdi-close"
            @click="bookCall = false"
          />
        </v-toolbar>
        <v-toolbar
          flat
          class="transparent"
          dense
        >
          <v-toolbar-title class="pb-2">
            <h2>Book a Call</h2>
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </div>
      <iframe
        width="100%"
        height="750px"
        src="https://willed.zohobookings.com.au/portal-embed#/customer/phone"
        frameborder="0"
      >
      </iframe>
    </v-navigation-drawer>
  </p>
</template>
<script>
import { remoteConfig } from '@/plugins/firebase'

import BtnOutline from '../../../components/ui/BtnOutline'
export default {
  name: 'BookCall',
  components: { BtnOutline },
  data: function () {
    return {
      offerBookCall: false,
      bookCall: false,
    }
  },
  async created() {
    await remoteConfig.fetchAndActivate().then(() => {
      this.offerBookCall = remoteConfig.getValue('book_call').asBoolean()
    })
  },
}
</script>
