<template>
  <div class="text-left">
    <div class="d-flex mb-4">
      <div class="flex-grow-0">
        <v-avatar
          color="#F2EADF"
          class="black--text font-weight-medium"
          size="30"
          >1</v-avatar
        >
      </div>
      <div class="flex-grow-1 px-4">
        <div class="w-subtitle">Create your Willed account</div>
        <p>
          Access your account anywhere and anytime to complete, update and
          download your Will.
        </p>
      </div>
    </div>
    <div class="d-flex mb-4">
      <div class="flex-grow-0">
        <v-avatar
          color="#F2EADF"
          class="black--text font-weight-medium"
          size="30"
          >2</v-avatar
        >
      </div>
      <div class="flex-grow-1 px-4">
        <div class="w-subtitle">Answer a few simple questions</div>
        <p>
          Complete our guided Will writing journey to create your Will online.
        </p>
      </div>
    </div>
    <div class="d-flex mb-4">
      <div class="flex-grow-0">
        <v-avatar
          color="#F2EADF"
          class="black--text font-weight-medium"
          size="30"
          >3</v-avatar
        >
      </div>
      <div class="flex-grow-1 px-4">
        <div class="w-subtitle">Print & Sign your Will</div>
        <p>Only pay once you are ready to download your Will.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegistrationHooray',
  components: {},
  data: function () {
    return {}
  },
}
</script>

<style lang="scss"></style>
